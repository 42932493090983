import React from "react";
import { Form, Input, Modal, Message } from "./Jäsenlomake";
import "styled-components/macro";
import { Rekisteriseloste } from "./Rekisteriseloste";

export const Sisäänkirjautuminen: React.FC<{
  onTunniste: (arg0: string) => void;
  plain?: boolean;
}> = ({ onTunniste, plain }) => {
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState<undefined | string>(undefined);
  const [showRekisteriseloste, setShowRekisteriseloste] = React.useState(false);
  const [error, setError] = React.useState<undefined | string>(undefined);

  return (
    <>
      <Form
        width={480}
        onSubmit={e => {
          e.preventDefault();
          if (typeof code === "undefined") {
            (async () => {
              const response = await fetch(`/api/login`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ Sähköposti: email }),
              });
              const data = await response.json();
              if (response.status !== 200) return setError(data.error);
              if (typeof data["Vahvistuskoodi"] !== "undefined")
                setCode(data["Vahvistuskoodi"]);
              else setCode("");
            })();
          }
          if (typeof code !== "undefined") {
            (async () => {
              const response = await fetch(`/api/login`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  Sähköposti: email,
                  Vahvistuskoodi: code,
                }),
              });
              const data = await response.json();
              if (response.status !== 200) return setError(data.error);
              if (typeof data["Tunniste"] !== "undefined")
                onTunniste(data["Tunniste"]);
            })();
          }
        }}
      >
        {!plain && (
          <div
            css={`
              font-family: Arial, sans-serif;
              margin-top: 2rem;
              margin-bottom: 0.75rem;
              > h1 {
                font-size: 24pt;
                line-height: 1.125;
                margin: 0;
              }
              > h2 {
                font-size: 18pt;
                line-height: 1.125;
                margin: 0;
                font-weight: 400;
                margin-bottom: 1em;
              }
              > p {
                font-size: 12pt;
                line-height: 1.25;
                margin: 0;
                font-weight: 300;
              }
            `}
          >
            <h1>Sundsbergin Kartanonrannan asukasyhdistys ry</h1>
            <h2>Omat jäsentiedot</h2>
            <p>
              Kirjaudu sisään sähköpostiosoitteellasi. Saat sähköpostiisi
              vahvistuskoodin, jonka vahvistamalla pääset Sundsbergin
              Kartanonrannan asukasyhdistyksen jäsensivuille. Jos olet uusi
              jäsen ja haluat liittyä asukasyhdistykseen, valitse kirjautumisen
              jälkeen "Liity jäseneksi".
            </p>
          </div>
        )}
        <fieldset>
          <legend>Sisäänkirjautuminen</legend>
          {typeof code === "undefined" && (
            <>
              <div>
                <Input label="Sähköpostiosoite" required autoFocus>
                  {({ inputRef }) => (
                    <input
                      ref={inputRef}
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                  )}
                </Input>
              </div>
            </>
          )}
          {typeof code !== "undefined" && (
            <>
              <div>
                <Input label="Sähköpostiosoite" required>
                  {() => <>{email}</>}
                </Input>
              </div>
              <div>
                <Input label="Vahvistuskoodi" required autoFocus>
                  {({ inputRef }) => (
                    <input
                      ref={inputRef}
                      value={code}
                      onChange={e => {
                        setCode(e.target.value);
                      }}
                    />
                  )}
                </Input>
              </div>
            </>
          )}
        </fieldset>
        <div
          css={`
            margin-top: 20px;
          `}
        >
          <button
            type="submit"
            css={`
              margin-bottom: 20px;
              transition-duration: 0.15s;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-property: background-color, border-color, color,
                box-shadow;
              background: #5850ec;
              color: #fff;
              border: 1px solid transparent;
              border-radius: 0.375rem;
              padding: 0.5rem 1rem;
              outline: none;
              font-size: 18px;
              cursor: pointer;
              &:hover {
                background: #6875f5;
              }
              &:focus {
                box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                border-color: #5145cd;
              }
            `}
          >
            Jatka
          </button>
        </div>
        {!showRekisteriseloste && !plain && (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <a
            href=""
            css={`
              font-family: Arial, sans-serif;
              text-decoration: none;
              color: #00e;
              &:hover {
                text-decoration: underline;
              }
            `}
            onClick={e => {
              e.preventDefault();
              setShowRekisteriseloste(true);
            }}
          >
            Rekisteriseloste
          </a>
        )}
        {showRekisteriseloste && (
          <Modal
            onClose={() => {
              setShowRekisteriseloste(false);
            }}
          >
            <Rekisteriseloste />
          </Modal>
        )}
      </Form>
      {typeof error !== "undefined" && (
        <Message
          onClose={() => {
            setError(undefined);
          }}
        >
          <p
            css={`
              margin: 0;
              margin-bottom: 10px;
            `}
          >
            {error}
          </p>
        </Message>
      )}
    </>
  );
};
