import React from "react";
import ReactDOM from "react-dom";
import { Sisäänkirjautuminen } from "./Sisäänkirjautuminen";
import Jäsenlomake, { Form, Input, Perhe } from "./Jäsenlomake";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { createGlobalStyle } from "styled-components/macro";
import { AdminPanel, AdminView } from "./Admin";
import { SendMoney } from "./SendMoney";
import type {} from "styled-components/cssprop";
import { PayoutView, PayoutOneView } from "./Payout";

export const SessionContext = React.createContext<{
  tunniste: string;
  clearTunniste: () => void;
}>({ tunniste: "", clearTunniste: () => {} });

export const usePersistedState = <T extends any>(
  key: string,
  initial: () => T
): [T, (arg0: T) => void] => {
  const getStore = () => {
    let store = JSON.parse(window.localStorage.getItem("store")!);
    if (!(typeof store === "object" && store !== null && !Array.isArray(store)))
      store = {};
    return store;
  };
  const [state, setState] = React.useState<T>(() => {
    try {
      return getStore()[key].value;
    } catch (err) {
      return initial();
    }
  });
  const setPersistedState = (value: T) => {
    setState(value);
    try {
      const store = getStore();
      store[key] = { updated_at: Date.now(), value: value };
      localStorage.setItem("store", JSON.stringify(store));
    } catch (err) {}
  };
  return [state, setPersistedState];
};

const OmatTiedot: React.FC = () => {
  const { tunniste, clearTunniste } = React.useContext(SessionContext);
  const [profile, setProfile] = React.useState<
    undefined | { Sähköposti: string; Perhe?: Perhe }
  >(undefined);
  React.useLayoutEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/profile`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      if (response.status === 403) return clearTunniste();
      const data = await response.json();
      if (cancel) return;
      setProfile(data);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste, clearTunniste]);
  const omaRivi = React.useMemo(() => {
    if (typeof profile === "undefined") return undefined;
    if (typeof profile["Perhe"] === "undefined") return undefined;
    return profile["Perhe"]["Jäsenet"].find(
      v =>
        typeof v["Sähköposti"] !== "undefined" &&
        v["Sähköposti"].toLowerCase() === profile["Sähköposti"].toLowerCase()
    );
  }, [profile]);
  if (typeof profile === "undefined") return null;
  return (
    <>
      {typeof omaRivi !== "undefined" && (
        <>
          <Form width={480}>
            <fieldset>
              <legend>Sundsbergin Kartanonrannan asukasyhdistys</legend>
              <div>
                <Input label="Sähköpostiosoite">
                  {() => <div>{profile["Sähköposti"]}</div>}
                </Input>
              </div>
              <div>
                <Input label="Etunimi">
                  {() => <div>{omaRivi["Etunimi"]}</div>}
                </Input>
                <Input label="Sukunimi">
                  {() => <div>{omaRivi["Sukunimi"]}</div>}
                </Input>
              </div>
              <div>
                <Input label="Asukasyhdistyksen jäsen">
                  {() => (
                    <div className="control">
                      <input type="checkbox" checked /> Olet asukasyhdistyksen
                      jäsen
                    </div>
                  )}
                </Input>
                <Input label="Jäsentiedot">
                  {() => (
                    <div>
                      <Link
                        to="/tiedot"
                        css={`
                          color: #0000ee;
                          text-decoration: none;
                          &:hover {
                            text-decoration: underline;
                          }
                        `}
                      >
                        Muokkaa jäsentietoja
                      </Link>
                    </div>
                  )}
                </Input>
              </div>
            </fieldset>
            <div style={{ marginTop: 20 }}>
              <button
                css={`
                  margin-bottom: 20px;
                  transition-duration: 0.15s;
                  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                  transition-property: background-color, border-color, color,
                    box-shadow;
                  background: #5850ec;
                  color: #fff;
                  border: 1px solid transparent;
                  border-radius: 0.375rem;
                  padding: 0.5rem 1rem;
                  outline: none;
                  font-size: 18px;
                  cursor: pointer;
                  &:hover {
                    background: #6875f5;
                  }
                  &:focus {
                    box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                    border-color: #5145cd;
                  }
                `}
                onClick={e => {
                  e.preventDefault();
                  (async () => {
                    await fetch(`/api/logout`, {
                      method: "POST",
                      mode: "cors",
                      cache: "no-cache",
                      headers: {
                        Authorization: `Tunniste ${tunniste}`,
                      },
                    });
                    clearTunniste();
                  })();
                }}
              >
                Kirjaudu ulos
              </button>
            </div>
          </Form>
        </>
      )}
      {typeof omaRivi === "undefined" && (
        <>
          <Form width={480}>
            <fieldset>
              <legend>Sundsbergin Kartanonrannan asukasyhdistys</legend>
              <div>
                <Input label="Sähköpostiosoite">
                  {() => <div>{profile["Sähköposti"]}</div>}
                </Input>
              </div>
              <div>
                <Input label="Asukasyhdistyksen jäsen">
                  {() => (
                    <div className="control">
                      <input type="checkbox" /> Et ole vielä asukasyhdistyksen
                      jäsen.
                    </div>
                  )}
                </Input>
                <Input label="Jäsentiedot">
                  {() => (
                    <div>
                      <div>
                        <Link
                          to="/tiedot"
                          css={`
                            display: inline-block;
                            transition-duration: 0.15s;
                            transition-timing-function: cubic-bezier(
                              0.4,
                              0,
                              0.2,
                              1
                            );
                            transition-property: background-color, border-color,
                              color, box-shadow;
                            background: #5850ec;
                            color: #fff;
                            border: 1px solid transparent;
                            border-radius: 0.375rem;
                            padding: 0.5rem 1rem;
                            outline: none;
                            font-size: 18px;
                            cursor: pointer;
                            &:hover {
                              background: #6875f5;
                            }
                            &:focus {
                              box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                              border-color: #5145cd;
                            }
                            text-decoration: none;
                          `}
                        >
                          Liity jäseneksi
                        </Link>
                      </div>
                    </div>
                  )}
                </Input>
              </div>
            </fieldset>
            <p
              css={`
                font-family: Arial, sans-serif;
              `}
            >
              Sähköpostiosoitteellasi ei löytynyt jäsentietoja. Jos
              sähköpostiosoitteesi on muuttunut, voit pyytää toista samassa
              taloudessa asuvaa henkilöä päivittämään sen. Voit myös lähettää
              sähköpostia osoitteeseen{" "}
              <a href="mailto:palaute@sundsberg.net">palaute@sundsberg.net</a>{" "}
              niin päivitämme tietosi ajan tasalle.
            </p>
            <div style={{ marginTop: 20 }}>
              <button
                css={`
                  margin-bottom: 20px;
                  transition-duration: 0.15s;
                  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                  transition-property: background-color, border-color, color,
                    box-shadow;
                  background: #5850ec;
                  color: #fff;
                  border: 1px solid transparent;
                  border-radius: 0.375rem;
                  padding: 0.5rem 1rem;
                  outline: none;
                  font-size: 18px;
                  cursor: pointer;
                  &:hover {
                    background: #6875f5;
                  }
                  &:focus {
                    box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                    border-color: #5145cd;
                  }
                `}
                onClick={e => {
                  e.preventDefault();
                  (async () => {
                    await fetch(`/api/logout`, {
                      method: "POST",
                      mode: "cors",
                      cache: "no-cache",
                      headers: {
                        Authorization: `Tunniste ${tunniste}`,
                      },
                    });
                    clearTunniste();
                  })();
                }}
              >
                Kirjaudu ulos
              </button>
            </div>
          </Form>
        </>
      )}
      <SendMoney />
      <AdminPanel />
    </>
  );
};

const App: React.FC = () => {
  const [tunniste, setTunniste] = usePersistedState<undefined | string>(
    "tunniste",
    () => undefined
  );
  const clearTunniste = React.useCallback(() => {
    setTunniste(undefined);
  }, [setTunniste]);
  return (
    <>
      {typeof tunniste === "undefined" && (
        <Sisäänkirjautuminen
          onTunniste={tunniste => {
            setTunniste(tunniste);
          }}
        />
      )}
      {typeof tunniste !== "undefined" && (
        <>
          <SessionContext.Provider value={{ tunniste, clearTunniste }}>
            <Router>
              <Switch>
                <Route path="/" exact>
                  <OmatTiedot />
                </Route>
                <Route path="/tiedot" exact>
                  <Jäsenlomake />
                </Route>
                <Route path="/admin" exact>
                  <AdminView />
                </Route>
                <Route path="/payout" exact>
                  <PayoutView />
                </Route>
                <Route
                  path="/payout/:id"
                  exact
                  render={({
                    match: {
                      params: { id },
                    },
                  }) => (
                    <>
                      <PayoutOneView id={id} />
                    </>
                  )}
                />
                <Redirect to="/" />
              </Switch>
            </Router>
          </SessionContext.Provider>
        </>
      )}
    </>
  );
};

const CSS = createGlobalStyle`
body {
  background: #f0f0f0;
}
`;

ReactDOM.render(
  <React.StrictMode>
    <App />
    <CSS />
  </React.StrictMode>,
  document.getElementById("root")
);
