import React from "react";
import "styled-components/macro";
import ReactMarkdown from "react-markdown";

const Säännöt: React.FC = () => {
  return (
    <ReactMarkdown
      css={`
        ol {
          list-style-position: inside;
          padding: 0;
        }
      `}
      source={`
#### 1. Yhdistyksen nimi ja kotipaikka

Yhdistyksen nimi on Sundsbergin Kartanonrannan asukasyhdistys ry, Sundsbergs Herrgårdstrandens invånareförening rf ja sen kotipaikka on Kirkkonummen kunta.

#### 2. Tarkoitus ja toiminnan laatu

Yhdistyksen tarkoituksena on toimia Sundsbergin alueella asuvien yhdyssiteenä, edunvalvojana ja viihtyvyyden lisääjänä huolehtimalla erilaisista aluetta tai asumista koskevista asioista.

Yhdistys osallistuu Kirkkonummen kehittämiseen edistämällä yhteiskunnallista ja yleishyödyllistä toimintaa.

Tarkoituksensa toteuttamiseksi yhdistys

1. tukee aatteellisesti ja toiminnallisesti erilaista asukastoiminnan järjestämistä Sundsbergin Kartanorannan alueella sekä järjestää sitä itse tai yhdessä muiden tahojen kanssa,
2. selvittää ja seuraa kaikkia asumiseen ja ympäristöön liittyviä kysymyksiä,
3. vaikuttaa asukkaiden etujen mukaisen asunto-, ympäristö-, vapaa-aika- ja kunnallisasioiden aikaansaamiseen alueella, tekemällä esityksiä ja aloitteita,
4. harjoittaa maksutonta neuvontaa asumiseen liittyvissä oikeudellisissa ja muissa kysymyksissä ja
5. harjoittaa julkaisutoimintaa.

Toimintansa tukemiseksi yhdistys

1. ottaa vastaan lahjoituksia, avustuksia ja testamentteja,
2. järjestää erilaisia juhla-, huvi- ja koulutustapahtumia.

#### 3. Jäsenet

Yhdistyksen varsinaiseksi jäseneksi voi liittyä jokainen Sundsbergin alueen asukas tai loma-asukas, joka hyväksyy yhdistyksen tarkoituksen ja säännöt.

Yhdistyksen hallitus voi ottaa kannatusjäseneksi henkilöitä, jotka haluavat tukea yhdistyksen toimintaa tai kutsua kunniajäseneksi yhdistyksen toiminnassa ansioituneita henkilöitä.

Jäsenet hyväksyy hakemuksesta yhdistyksen hallitus.

#### 4. Jäsenen eroaminen ja erottaminen

Jäsenellä on oikeus erota yhdistyksestä ilmoittamalla siitä kirjallisesti hallitukselle tai sen puheenjohtajalle taikka ilmoittamalla erosta yhdistyksen kokouksessa merkittäväksi pöytäkirjaan.

Hallitus voi erottaa jäsenen yhdistyksestä, jos jäsen on jättänyt erääntyneen jäsenmaksunsa maksamatta tai muuten jättänyt täyttämättä ne velvoitukset, joihin hän on yhdistykseen liittymällä sitoutunut tai on menettelyllään yhdistyksessä tai sen ulkopuolella huomattavasti vahingoittanut yhdistystä tai ei enää täytä laissa taikka yhdistyksen säännöissä mainittuja jäsenyyden ehtoja.

#### 5. Liittymis- ja jäsenmaksu

Jäseniltä perittävän liittymismaksun ja vuotuisen jäsenmaksun suuruudesta päättää vuosikokous erikseen varsinaisille jäsenille ja kannatusjäsenille.

#### 6. Hallitus

Yhdistyksen asioita hoitaa hallitus, johon kuuluu vuosikokouksessa valitut 6-11 varsinaista jäsentä.

Hallituksen toimikausi on vuosikokousten välinen aika.

Hallitus valitsee keskuudestaan puheenjohtajan, varapuheenjohtajan sekä ottaa keskuudestaan tai ulkopuoleltaan sihteerin, rahastonhoitajan ja muut tarvittavat toimihenkilöt.

Hallitus kokoontuu puheenjohtajan tai hänen estyneenä ollessaan varapuheenjohtajan kutsusta, kun he katsovat siihen olevan aihetta tai kun vähintään puolet hallituksen jäsenistä sitä vaatii.

Hallitus on päätösvaltainen, kun vähintään puolet sen jäsenistä, puheenjohtaja tai varapuheenjohtaja mukaan luettuna on läsnä. Äänestykset ratkaistaan yksinkertaisella äänten enemmistöllä. Äänten mennessä tasan ratkaisee puheenjohtajan ääni, vaaleissa kuitenkin arpa.

#### 7. Yhdistyksen nimen kirjoittaminen

Yhdistyksen nimen kirjoittaa hallituksen puheenjohtaja, varapuheenjohtaja, sihteeri tai rahastonhoitaja, kaksi yhdessä.

#### 8. Tilikausi ja tilintarkastus

Yhdistyksen tilikausi on kalenterivuosi.

Tilinpäätös tarvittavine asiakirjoineen ja hallituksen vuosikertomus on annettava tilintarkastajille viimeistään kolme viikkoa ennen vuosikokousta. Tilintarkastajien tulee antaa kirjallinen lausuntonsa viimeistään kaksi viikkoa ennen vuosikokousta hallitukselle.

#### 9. Yhdistyksen kokoukset

Yhdistyksen vuosikokous pidetään vuosittain hallituksen määräämänä päivänä helmi-huhtikuussa.

Ylimääräinen kokous pidetään, kun yhdistyksen kokous niin päättää tai kun hallitus katsoo siihen olevan aihetta tai kun vähintään kymmenesosa (1/10) yhdistyksen äänioikeutetuista jäsenistä sitä hallitukselta erityisesti ilmoitettua asiaa varten kirjallisesti vaatii. Kokous on pidettävä kolmenkymmenen vuorokauden kuluessa siitä, kun vaatimus sen pitämisestä on esitetty hallitukselle.

Yhdistyksen kokouksissa on jokaisella varsinaisella jäsenellä yksi ääni. Kannatus- ja kunniajäsenillä on läsnäolo- ja puheoikeus.

Yhdistyksen kokouksen päätökseksi tulee, ellei säännöissä ole toisin määrätty, se mielipide, jota on kannattanut yli puolet annetuista äänistä. Äänten mennessä tasan ratkaisee kokouksen puheenjohtajan ääni, vaaleissa kuitenkin arpa.

#### 10. Yhdistyksen kokousten koollekutsuminen

Hallituksen on kutsuttava yhdistyksen kokoukset koolle vähintään 14 vuorokautta ennen kokousta jäsenille toimitetulla kirjeellä tai sähköpostilla jäsenen ilmoittamaan osoitteeseen.

#### 11. Vuosikokous

Yhdistyksen vuosikokouksessa käsitellään seuraavat asiat:

1. kokouksen avaus
2. valitaan kokouksen puheenjohtaja, sihteeri, kaksi pöytäkirjantarkastajaa ja tarvittaessa kaksi ääntenlaskijaa
3. todetaan kokouksen laillisuus ja päätösvaltaisuus
4. hyväksytään kokouksen työjärjestys
5. esitetään tilinpäätös, vuosikertomus ja tilintarkastajien lausunto
6. päätetään tilinpäätöksen vahvistamisesta ja vastuuvapauden myöntämisestä hallitukselle ja muille vastuuvelvollisille
7. vahvistetaan toimintasuunnitelma, tulo- ja menoarvio sekä liittymis- ja jäsenmaksujen suuruus
8. valitaan hallituksen jäsenet
9. valitaan kaksi tilintarkastajaa ja heille varatilintarkastajat
10. käsitellään muut kokouskutsussa mainitut asiat.

Mikäli yhdistyksen jäsen haluaa saada jonkin asian yhdistyksen vuosikokouksen käsiteltäväksi, on hänen ilmoitettava siitä kirjallisesti hallitukselle niin hyvissä ajoin, että asia voidaan sisällyttää kokouskutsuun.

#### 12. Sääntöjen muuttaminen ja yhdistyksen purkaminen

Päätös sääntöjen muuttamisesta ja yhdistyksen purkamisesta on tehtävä yhdistyksen kokouksessa vähintään kolmen neljäsosan (3/4) enemmistöllä annetuista äänistä. Kokouskutsussa on mainittava sääntöjen muuttamisesta tai yhdistyksen purkamisesta.

Yhdistyksen purkautuessa käytetään yhdistyksen varat yhdistyksen tarkoituksen edistämiseen purkamisesta päättävän kokouksen määräämällä tavalla. Yhdistyksen tullessa lakkautetuksi käytetään sen varat samaan tarkoitukseen.

Nämä säännöt on hyväksytty yhdistyksen perustavassa kokouksessa 30.6.2004.${`  `}
Sääntöjä muutettu vuosikokouksessa 27.4.2017

#### 1. Name and place of function

The name of the association is Sundsbergin Kartanonrannan asukasyhdistys ry (residents’ association), Sundsbergs Herrgårdstrandens invånareförening rf. The association is registered in the Municipality of Kirkkonummi, Finland.

#### 2. Purpose and objectives

The purpose of the association is to act as a link to all the residents of Sundsberg, to look after their interests and to increase the pleasantness of the area. It takes care of various issues related to Sundsberg and living in Sundsberg. The association shall participate in development of the municipality of Kirkkonummi by advancing social and public utility activities.

To fulfill its objectives, the association shall

1. Support ideologically and functionally various residents’ activities in Kartanonranta area in Sundsberg and organize activities itself or in cooperation with other parties
2. Clarify and follow-up all issues related to the residence and environment issues
3. Influence the development of housing, environmental, free-time and municipal issues in a way that is in the best interest of the residents by being active and taking the initiative.
4. Offer free of charge advice concerning the legal interest of the residents’ and in other issues
5. Carry on publishing activities

To support its activities, the association shall

1. Accept donations, sponsorships, and testaments
2. Organize various events (e.g., festival, sport and education)

#### 3. Membership

Membership is open to any permanent or temporary resident who lives Sundsberg and accepts the rules and objectives.

The Board of the association can accept individual as associate members when these individuals are willing to support the association’s activity. Honorary membership can be offered to any individual in recognition of outstanding special service to the association.

The Board of the association shall accept members after submission of an application.

#### 4. Members separation from the association

Any member has the right to separate from the association by informing the Board or its chairperson by letter, or by verbally asking the separation to be noted into the minutes of an association meeting.

The Board may dismiss any member if the member has not paid his/her annual fee, if the member has not fulfilled his/her responsibilities that he/she has adhered to by joining the association, if he/she has caused harm to the association by his/her conduct within or outside the association, or if he/she no longer fulfils the membership requirements set by the law or association rules.

#### 5. Membership fee

The Annual General Meeting shall decide on the amount of the entrance fee and the yearly membership fee of members and associate members.

#### 6. The Board

The Annual General Meeting shall elect the 6-11 members of the Board.

The term of office for the Board is until the next Annual General Meeting.

The Board shall elect from within its members a chairperson, a vice-chairperson, and elect from within or outside its members a secretary, a treasurer, and any other necessary officials.

The Board shall meet on invitation of the chairperson, or vice-chairperson (the chairperson being prevented) or whenever half of the Board members feel the need to have a meeting.

The members of the Board constitute a quorum when at least half of its members, including either the chairperson or vice-chairperson, are present. Votes shall be decided on the basis of majority. If votes end in a draw, the chairperson’s vote shall decide, except during elections when decisions are settled by lot.

#### 7. Signature rights of the association

The chairperson, vice-chairperson, secretary and treasurer have the right to sign on behalf of the association. Two signatures are required.

#### 8. The accounting period and auditing of accounts

The calendar year shall be the accounting period of the association.

The closing balance of the accounts, other related documents and the annual report from the Board shall be submitted to the accountant auditor(s) at least 3 weeks prior to the Annual General Meeting. The accountant auditor(s) shall submit their written statement to the Board at least two weeks prior to the Annual General Meeting.

#### 9. Association Meetings

The Annual General Meeting shall be arranged annually between February-April. The date shall be decided by the Board.

Any other official meetings may be held if this decision is made during an association meeting, if the Board feels a need to hold a meeting, or when at least one tenth (1/10) of the association members request the Board in writing (specifying the matter to be handled) to hold a meeting. The meeting shall be held within thirty (30) days of receiving such a request.

Every full member has one vote during association meetings. Associate and honorary members can be present as well as have the right to speak, however do not have the right to vote.

If not otherwise mentioned in this charter, the decisions of the association’s meeting are based on the majority vote i.e., opinion with more than half of given votes. If votes end in a draw, the chairperson’s vote shall decide, except during elections when decisions are settled by lot.

#### 10. Summoning of association meetings

The Board shall summon the association members to a meeting by informing them in writing or email, at least 14 days in advance.

#### 11. Annual General Meeting

The agenda for the Annual General Meeting shall include the following:

1. Opening of the meeting
2. Selection of a chairperson, secretary, two surveyors of the minutes, and two polling officers if applicable
3. Establishing the legality of the meeting and checking if the meeting has a quorum
4. Acceptance of the meeting agenda
5. Presentation of the account closing balance, annual report and statement from the accountant auditor(s)
6. Decision on the confirmation of the account closing balance, and granting discharge of liability to the Board members and other officials
7. Confirmation of the plan of action, budget, and amount of membership fee for the next calendar year
8. Selection of the Board
9. Selection of one to two accountant auditors and substitute auditors
10. Handling of other matters mentioned in the meeting invitation letter

If a member wants to discuss a particular subject during of the General Annual Meeting, he/she must inform the Board in writing about this matter well in advance so that it may be included in the meeting invitation letter.

#### 12. Amendments to the association rules and dissolving of the association

The decision to amend the association rules or dissolve the association must be taken at an association meeting with a three quarters (3/4) majority vote. The intention to amend the rules or dissolve the association shall be mentioned in the meeting invitation letter.

In the event of dissolving the association its funds shall be used to promote its objectives as decided upon at the meeting. In the event of the association being discontinued its funds shall be used in the same manner.

This charter is accepted in the foundation association meeting on the 30th June 2004.${`  `}
The charter has been modified by the Annual General Meeting on the 27th April 2017.
`}
    />
  );
};

export default Säännöt;
