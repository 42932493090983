import React from "react";
import "styled-components/macro";
import { SessionContext } from "./index";
import { Form, Input } from "./Jäsenlomake";
import { Link } from "react-router-dom";

export const AdminPanel: React.FC = () => {
  const { tunniste } = React.useContext(SessionContext);
  const [hasAccess, setHasAccess] = React.useState<boolean | undefined>(
    undefined
  );
  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/admin-data`, {
        method: "HEAD",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      setHasAccess(response.status === 200);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste]);
  return (
    <>
      {hasAccess && (
        <Form width={480}>
          <fieldset>
            <legend>Hallintapaneeli</legend>
            <div>
              <Input label="Jäsentiedot">
                {() => (
                  <div>
                    <Link
                      to="/admin"
                      css={`
                        color: #0000ee;
                        text-decoration: none;
                        &:hover {
                          text-decoration: underline;
                        }
                      `}
                    >
                      Avaa jäsenrekisteri
                    </Link>
                  </div>
                )}
              </Input>
            </div>
            <div>
              <Input label="Stripe Payout">
                {() => (
                  <div>
                    <Link
                      to="/payout"
                      css={`
                        color: #0000ee;
                        text-decoration: none;
                        &:hover {
                          text-decoration: underline;
                        }
                      `}
                    >
                      Stripe Payout
                    </Link>
                  </div>
                )}
              </Input>
            </div>
          </fieldset>
        </Form>
      )}
    </>
  );
};

export const AdminView: React.FC = () => {
  const { tunniste } = React.useContext(SessionContext);
  const [data, setData] = React.useState<any | undefined>(undefined);
  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/admin-data`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      if (response.status !== 200) return;
      const json = await response.json();
      if (cancel) return;
      setData(json);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste]);
  return (
    <>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </>
  );
};
