import React from "react";
import "styled-components/macro";
import { SessionContext } from "./index";

export const PayoutView: React.FC = () => {
  const { tunniste } = React.useContext(SessionContext);
  const [data, setData] = React.useState<
    { link: string; id: string; date: string; amount: string }[] | undefined
  >(undefined);
  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/payout`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      if (response.status !== 200) return;
      const json = await response.json();
      if (cancel) return;
      setData(json);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste]);
  if (typeof data === "undefined") return <></>;
  return (
    <>
      <pre>
        {data.map(({ link, id, date, amount }) => (
          <>
            <a href={link}>{id}</a> {date} {amount}
            <br />
          </>
        ))}
      </pre>
    </>
  );
};

export const PayoutOneView: React.FC<{ id: string }> = ({ id }) => {
  const { tunniste } = React.useContext(SessionContext);
  const [data, setData] = React.useState<{ text: string } | undefined>(
    undefined
  );
  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/payout/${encodeURIComponent(id)}`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      if (response.status !== 200) return;
      const json = await response.json();
      if (cancel) return;
      setData(json);
    })();
    return () => {
      cancel = true;
    };
  }, [id, tunniste]);
  if (typeof data === "undefined") return <>Lataa tietoja...</>;
  return (
    <>
      <pre>{data.text}</pre>
    </>
  );
};
