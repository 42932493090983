import React from "react";
import ReactMarkdown from "react-markdown";

export const Rekisteriseloste: React.FC = () => (
  <>
    <ReactMarkdown
      source={`
Henkilötietolaki (523/99) 10 §

Laatimispäivä 30.8.2020

1. Rekisterinpitäjä

Sundsbergin Kartanonrannan asukasyhdistys ry, Sundsbergs Herrgårdsstrandens invånareförening rf${`  `}
Kirsikkapolku 1${`  `}
02450 SUNDSBERG

2. Rekisteriasioista lisätietoa

Tietosuojavastaava:${`  `}
Pauli Koskelainen${`  `}
tietosuojavastaava@sundsberg.net

3. Rekisterin nimi

Sundsbergin Kartanonrannan asukasyhdistyksen jäsenrekisteri

4. Henkilötietojen käsittelyn tarkoitus (rekisterin käyttötarkoitus)

Jäsentietojen ylläpito.

5. Rekisterin tietosisältö

Rekisteriin kerätään rekisteröidyistä perustietoja, kuten:
- nimi
- osoite
- puhelin
- sähköposti
- kiinnostuksen kohteet

6. Säännönmukaiset tietolähteet

Jäseniltä saadut tiedot liittymisen yhteydessä.

7. Säännönmukaiset tietojen luovutukset ja tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle

Jäsentietoja ei luovuteta Sundsbergin Kartanonrannan asukasyhdistyksen ulkopuolelle.

8. Rekisterin suojauksen periaatteet 

Jäsenrekisterin tiedot on tallennettu rekisterinpitäjän järjestelmään, joka on suojattu käyttöjärjestelmän suojausohjelmiston avulla. Järjestelmään sisäänpääsy edellyttää käyttäjätunnuksen ja salasanan syöttämistä. Järjestelmä on myös suojattu palomuurein ja muiden teknisten keinojen avulla. Järjestelmään tallennettuihin rekisterin sisältämiin tietoihin pääsevät ja niitä ovat oikeutettuja käyttämään vain tietyt, ennalta määritellyt rekisterinpitäjän työntekijät. Rekisterin sisältämät tiedot sijaitsevat lukituissa ja vartioiduissa tiloissa.

9. Rekisteröidyn tarkastus-oikeus

- Yhdistyksen jäsenillä on oikeus tarkastaa, mitä häntä koskevia tietoja on tallennettu jäsenrekisteriin. Jäsenen on esitettävä tarkastuspyyntö rekisterinpitäjälle kirjallisesti omakätisesti allekirjoitetussa muodossa tai sitä vastaavalla tavalla varmennetussa asiakirjassa, taikka sähköpostitse.
- Jäsenellä on oikeus oikaista itseään koskevia jäsenrekisteriin tallennettuja tietoja siltä osin kuin ne ovat virheellisiä.
- Jäsenellä on oikeus saada häntä koskevat jo rekisteröidyt mainittuun tarkoitukseen tallennetut henkilötiedot poistetuksi.

10. Tiedon korjaaminen

Rekisterinpitäjä oikaisee, poistaa tai täydentää rekisterissä olevan, käsittelyn tarkoituksen kannalta virheellisen, tarpeettoman, puutteellisen tai vanhentuneen henkilötiedon oma-aloitteisesti tai rekisteröidyn vaatimuksesta. Rekisteröidyn tulee ottaa yhteyttä rekisterinpitäjän rekisteriasioista vastaavaan henkilöön tiedon korjaamiseksi.
`}
    />
  </>
);
