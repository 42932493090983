import React from "react";
import ReactDOM from "react-dom";
import { css } from "styled-components/macro";
import Säännöt from "./Säännöt";
import { produce } from "immer";
import { SessionContext } from ".";
import { useHistory, Link } from "react-router-dom";
import { Sisäänkirjautuminen } from "./Sisäänkirjautuminen";

export interface Perhe {
  Osoite: string;
  Laskutussähköposti: string;
  Jäsenet: Array<{
    Etunimi: string;
    Sukunimi: string;
    Syntymävuosi: string;
    Sähköposti?: string;
    Toimikunnat?: Array<string>;
    Jäsenmaksut?: Array<string>;
    "Harrastukset/kiinnostuksen kohteet"?: string;
    "Lisätään alueen yhteiselle sähköpostilistalle"?: true;
  }>;
  "Säännöt hyväksytty"?: string;
  "Poista kaikki tiedot"?: true;
}

export const Input: React.FC<{
  label?: string;
  required?: boolean;
  flex?: string;
  autoFocus?: boolean;
  children: (arg0: { inputRef: React.Ref<any> }) => JSX.Element;
}> = ({ flex, required, label, children, autoFocus }) => {
  const inputRef = React.useRef<any>(null);
  const [focus, setFocus] = React.useState(false);
  React.useLayoutEffect(() => {
    const focus = () => {
      setFocus(true);
    };
    const blur = () => {
      setFocus(false);
    };
    const input = inputRef.current!;
    if (input === null) return;
    input.addEventListener("focus", focus, true);
    input.addEventListener("blur", blur, true);
    if (autoFocus) input.focus();
    return () => {
      input.removeEventListener("focus", focus, true);
      input.removeEventListener("blur", blur, true);
    };
  }, [autoFocus]);
  return (
    <div
      style={{ flex }}
      className={focus ? "focus" : undefined}
      onClick={e => {
        let el = e.target as any;
        while (el !== null && typeof el !== "undefined") {
          if (
            typeof el.classList !== "undefined" &&
            el.classList !== null &&
            el.classList.contains("control")
          )
            return;
          el = el.parentNode;
        }
        e.preventDefault();
        const input = inputRef.current!;
        if (input === null) return;
        input.focus();
      }}
    >
      {typeof label !== "undefined" && (
        <label>
          {label}
          {required && (
            <>
              {" "}
              <span style={{ color: "#f44", fontWeight: "bold" }}>*</span>
            </>
          )}
        </label>
      )}
      {children({ inputRef })}
    </div>
  );
};

export const Modal: React.FC<{ onClose: () => void }> = ({
  children,
  onClose,
}) => {
  const el = React.useMemo(() => document.createElement("div"), []);
  React.useLayoutEffect(() => {
    document.body.appendChild(el);
    document.body.style["overflow"] = "hidden";
    return () => {
      document.body.style["overflow"] = "";
    };
  }, [el]);
  return (
    <>
      {ReactDOM.createPortal(
        <div
          css={`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;
            background: #fff;
            padding: 16px;
          `}
        >
          {children}
          <div>
            <button
              onClick={e => {
                e.preventDefault();
                onClose();
              }}
            >
              Sulje
            </button>
          </div>
        </div>,
        el
      )}
    </>
  );
};

export const Message: React.FC<{ onClose: () => void }> = ({
  children,
  onClose,
}) => {
  const el = React.useMemo(() => document.createElement("div"), []);
  React.useLayoutEffect(() => {
    document.body.appendChild(el);
    document.body.style["overflow"] = "hidden";
    return () => {
      document.body.style["overflow"] = "";
    };
  }, [el]);
  return (
    <>
      {ReactDOM.createPortal(
        <div
          css={`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: scroll;
            background: rgba(0, 0, 0, 0.25);
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            > div {
              padding: 16px;
              background: #fff;
              color: #000;
              width: 100%;
              max-width: 480px;
            }
          `}
        >
          <div>
            {children}
            <div>
              <button
                onClick={e => {
                  e.preventDefault();
                  onClose();
                }}
              >
                Sulje
              </button>
            </div>
          </div>
        </div>,
        el
      )}
    </>
  );
};

export const Form: React.FC<{
  width?: number;
  onSubmit?: (arg0: React.FormEvent<HTMLFormElement>) => void;
}> = ({ width, children, onSubmit }) => (
  <form
    onSubmit={e => {
      if (typeof onSubmit !== "undefined") return onSubmit(e);
      e.preventDefault();
    }}
    css={`
      max-width: 768px;
      ${typeof width !== "undefined" &&
      css`
        max-width: ${width}px;
      `}
      margin: 0 auto 0;
      > fieldset {
        font-family: Arial, sans-serif;
        border: none;
        margin: 0;
        padding: 0;
        > legend {
          border-bottom: 4px solid #000;
          color: #404040;
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding-top: 20px;
          font-size: 18px;
          line-height: 1.5;
          font-weight: bold;
          margin-bottom: 0;
        }
        > div {
          display: flex;
          border-bottom: 1px solid #333;
          @media (max-width: 640px) {
            display: block;
            border-bottom: none;
          }
          > div {
            &:hover,
            &.focus {
              background: #fff;
              cursor: text;
            }
            display: block;
            flex: 1;
            &:not(:last-child) {
              border-right: 1px solid #333;
            }
            @media (max-width: 640px) {
              border-right: 0px !important;
              border-bottom: 1px solid #333;
            }
            padding: 8px;
            > label {
              display: block;
              font-size: 12px;
              text-transform: uppercase;
              letter-spacing: 1px;
              margin-bottom: 4px;
              color: #333;
              pointer-events: none;
              user-select: none;
            }
            > textarea,
            > input {
              display: block;
              width: 100%;
              border: 0;
              appearance: none;
              font-size: 18px;
              line-height: 1;
              outline: none;
              background: transparent;
              resize: none;
            }
            > select {
              font-size: 18px;
              line-height: 1;
            }
            > textarea {
              font-family: inherit;
            }
          }
        }
      }
    `}
  >
    {children}
  </form>
);

const App: React.FC = () => {
  const { tunniste, clearTunniste } = React.useContext(SessionContext);
  const [serverData, setServerData] = React.useState<undefined | null | Perhe>(
    undefined
  );
  const history = useHistory();
  React.useLayoutEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/perhe`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      if (response.status === 403) return clearTunniste();
      const data = await response.json();
      if (cancel) return;
      if (response.status !== 200) return setError(data.error);
      setServerData(data);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste, clearTunniste]);
  const [profile, setProfile] = React.useState<
    undefined | { Sähköposti: string; Perhe?: Perhe }
  >(undefined);
  React.useLayoutEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/profile`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      if (response.status === 403) return clearTunniste();
      const data = await response.json();
      if (cancel) return;
      setProfile(data);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste, clearTunniste]);
  const [showSäännöt, setShowSäännöt] = React.useState(false);
  const [input, _setInput] = React.useState<undefined | Perhe>(undefined);
  const setInput = React.useCallback(
    (cb: (arg0: Perhe) => void) => {
      _setInput(produce(input, cb));
    },
    [input]
  );
  const [saving, setSaving] = React.useState(false);
  React.useLayoutEffect(() => {
    if (typeof serverData === "undefined") return;
    if (serverData === null) {
      _setInput({
        Osoite: "",
        Laskutussähköposti: "",
        Jäsenet: [
          {
            Etunimi: "",
            Sukunimi: "",
            Syntymävuosi: "",
          },
        ],
      });
      return;
    }
    _setInput({
      ...serverData,
      "Säännöt hyväksytty": new Date().toISOString().substr(0, 10),
    });
  }, [serverData]);
  const [error, setError] = React.useState<undefined | string>(undefined);
  const [showNewEmailModal, setShowNewEmailModal] = React.useState(false);
  if (typeof input === "undefined") return null;
  if (typeof profile === "undefined") return null;
  return (
    <>
      <Form>
        <fieldset>
          <legend>Yleiset tiedot</legend>
          <div>
            <Input label="Katuosoite" required flex={"4"}>
              {({ inputRef }) => (
                <input
                  ref={inputRef}
                  value={input["Osoite"]}
                  onChange={e => {
                    setInput(draft => {
                      draft["Osoite"] = e.target.value;
                    });
                  }}
                />
              )}
            </Input>
            <Input label="Jäseniä" required>
              {({ inputRef }) => (
                <select
                  ref={inputRef}
                  value={`${input["Jäsenet"].length}`}
                  onChange={e => {
                    const num = parseInt(e.target.value, 10);
                    setInput(draft => {
                      draft["Jäsenet"] = draft["Jäsenet"].slice(0, num);
                      while (draft["Jäsenet"].length < num)
                        draft["Jäsenet"].push({
                          Etunimi: "",
                          Sukunimi: "",
                          Syntymävuosi: "",
                        });
                    });
                  }}
                >
                  {Array.from({ length: 10 }).map((_, k) => (
                    <option value={1 + k}>{1 + k}</option>
                  ))}
                </select>
              )}
            </Input>
          </div>
        </fieldset>
        {input["Jäsenet"].map((v, k) => (
          <fieldset>
            <legend>
              Jäsen {k + 1}
              {input["Jäsenet"].length > 1 && (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <a
                  style={{
                    marginLeft: "1ch",
                    cursor: "pointer",
                    userSelect: "none",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  onClick={e => {
                    e.preventDefault();
                    setInput(draft => {
                      draft["Jäsenet"].splice(k, 1);
                    });
                  }}
                  href=""
                  title="Poista tämän jäsenen tiedot"
                >
                  &times;
                </a>
              )}
            </legend>
            <div>
              <Input label="Etunimi" required flex="2">
                {({ inputRef }) => (
                  <input
                    ref={inputRef}
                    value={v["Etunimi"]}
                    onChange={e => {
                      setInput(draft => {
                        draft["Jäsenet"][k]["Etunimi"] = e.target.value;
                      });
                    }}
                  />
                )}
              </Input>
              <Input label="Sukunimi" required flex="2">
                {({ inputRef }) => (
                  <input
                    ref={inputRef}
                    value={v["Sukunimi"]}
                    onChange={e => {
                      setInput(draft => {
                        draft["Jäsenet"][k]["Sukunimi"] = e.target.value;
                      });
                    }}
                  />
                )}
              </Input>
              <Input label="Syntymävuosi" required>
                {({ inputRef }) => (
                  <select
                    ref={inputRef}
                    value={v["Syntymävuosi"]}
                    onChange={e => {
                      setInput(draft => {
                        draft["Jäsenet"][k]["Syntymävuosi"] = e.target.value;
                      });
                    }}
                  >
                    <option></option>
                    {Array.from({ length: 100 }).map((_, k) => (
                      <option value={new Date().getFullYear() - k}>
                        {new Date().getFullYear() - k}
                      </option>
                    ))}
                  </select>
                )}
              </Input>
            </div>
            <div>
              <Input label="Sähköpostiosoite">
                {({ inputRef }) => (
                  <input
                    ref={inputRef}
                    type="email"
                    value={v["Sähköposti"]}
                    onChange={e => {
                      setInput(draft => {
                        draft["Jäsenet"][k]["Sähköposti"] = e.target.value;
                        if (draft["Jäsenet"][k]["Sähköposti"] === "") {
                          delete draft["Jäsenet"][k]["Sähköposti"];
                          delete draft["Jäsenet"][k][
                            "Lisätään alueen yhteiselle sähköpostilistalle"
                          ];
                          delete draft["Jäsenet"][k]["Toimikunnat"];
                          delete draft["Jäsenet"][k]["Jäsenmaksut"];
                        }
                        if (
                          typeof draft["Jäsenet"].find(
                            v => v["Sähköposti"] === draft["Laskutussähköposti"]
                          ) === "undefined"
                        )
                          delete (draft as any)["Laskutussähköposti"];
                      });
                    }}
                  />
                )}
              </Input>
            </div>
            {typeof v["Sähköposti"] !== "undefined" && (
              <>
                <div>
                  <Input>
                    {({ inputRef }) => (
                      <div className="control">
                        <label
                          css={`
                            user-select: none;
                          `}
                        >
                          <input
                            type="checkbox"
                            ref={inputRef}
                            checked={
                              v["Lisätään alueen yhteiselle sähköpostilistalle"]
                            }
                            onChange={e => {
                              setInput(draft => {
                                if (e.target.checked)
                                  draft["Jäsenet"][k][
                                    "Lisätään alueen yhteiselle sähköpostilistalle"
                                  ] = true;
                                else
                                  delete draft["Jäsenet"][k][
                                    "Lisätään alueen yhteiselle sähköpostilistalle"
                                  ];
                              });
                            }}
                          />{" "}
                          Haluan että sähköpostiosoite lisätään alueen
                          yhteiselle sähköpostilistalle. (Sähköpostiosoitetta ei
                          luovuteta yhdistyksen hallituksen ulkopuolelle)
                        </label>
                      </div>
                    )}
                  </Input>
                </div>
                <div>
                  <Input label="Olen kiinnostunut seuraavista asukasyhdistyksen toiminnoista">
                    {() => {
                      const toiminnot = [
                        "Tilavuokraus",
                        "Omppukerho",
                        "Koirakerho",
                        "Liikunta",
                        "Aikuisten liikuntaryhmät",
                        "Lasten liikuntaryhmät",
                        "Lentopallo",
                        "Sähly",
                        "Koripallo",
                        "Valokuvaus",
                        "Ilotulitus",
                        "Kesäjuhlat",
                        "Nuorisotoiminta",
                        "Ihanat naiset",
                        "Talkootoiminta",
                        "Kyläsauna",
                        "Soutuvene",
                        "Palstat",
                      ];
                      const groups = Array.from({
                        length: 3,
                      }).map((_, k, self) =>
                        toiminnot.slice(
                          k * Math.ceil(toiminnot.length / self.length),
                          (k + 1) * Math.ceil(toiminnot.length / self.length)
                        )
                      );
                      return (
                        <div className="control">
                          <div
                            css={`
                              display: grid;
                              grid-template-columns: repeat(
                                ${groups.length},
                                1fr
                              );
                              grid-gap: 16px;
                              @media (max-width: 640px) {
                                display: block;
                                > div:not(:last-child) {
                                  margin-bottom: 5px;
                                }
                              }
                            `}
                          >
                            {groups.map(group => (
                              <div>
                                <div
                                  css={`
                                    display: grid;
                                    grid-gap: 5px;
                                  `}
                                >
                                  {group.map(v2 => (
                                    <>
                                      <label
                                        css={`
                                          user-select: none;
                                        `}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            (v.Toimikunnat || []).indexOf(
                                              v2
                                            ) !== -1
                                          }
                                          onChange={e => {
                                            setInput(draft => {
                                              if (
                                                typeof draft["Jäsenet"][k]
                                                  .Toimikunnat === "undefined"
                                              )
                                                draft["Jäsenet"][
                                                  k
                                                ].Toimikunnat = [];
                                              if (e.target.checked)
                                                draft["Jäsenet"][
                                                  k
                                                ].Toimikunnat!.push(v2);
                                              else
                                                draft["Jäsenet"][
                                                  k
                                                ].Toimikunnat = draft[
                                                  "Jäsenet"
                                                ][k].Toimikunnat!.filter(
                                                  v3 => v3 !== v2
                                                );
                                              if (
                                                draft["Jäsenet"][k].Toimikunnat!
                                                  .length === 0
                                              )
                                                delete draft["Jäsenet"][k]
                                                  .Toimikunnat;
                                            });
                                          }}
                                        />{" "}
                                        {v2}
                                      </label>
                                    </>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  </Input>
                </div>
              </>
            )}
            <div>
              <Input label="Kerro harrastuksistasi tai kiinnostuksen kohteista">
                {({ inputRef }) => (
                  <textarea
                    ref={inputRef}
                    rows={4}
                    value={v["Harrastukset/kiinnostuksen kohteet"]}
                    onChange={e => {
                      setInput(draft => {
                        draft["Jäsenet"][k][
                          "Harrastukset/kiinnostuksen kohteet"
                        ] = e.target.value;
                        if (
                          draft["Jäsenet"][k][
                            "Harrastukset/kiinnostuksen kohteet"
                          ] === ""
                        )
                          delete draft["Jäsenet"][k][
                            "Harrastukset/kiinnostuksen kohteet"
                          ];
                      });
                    }}
                  />
                )}
              </Input>
            </div>
          </fieldset>
        ))}
        <fieldset>
          <legend>Jäsenmaksut</legend>
          <div>
            <Input
              label="Valitse sähköpostiosoite johon yhdistys lähettää laskut"
              required
            >
              {({ inputRef }) => (
                <select
                  ref={inputRef}
                  value={input["Laskutussähköposti"]}
                  onChange={e => {
                    setInput(draft => {
                      draft["Laskutussähköposti"] = e.target.value;
                    });
                  }}
                >
                  <option>- Valitse -</option>
                  {input["Jäsenet"]
                    .filter(
                      ({ Sähköposti }) => typeof Sähköposti !== "undefined"
                    )
                    .map(v => (
                      <option value={v.Sähköposti} key={v.Sähköposti}>
                        {v.Sähköposti}
                      </option>
                    ))}
                </select>
              )}
            </Input>
          </div>
        </fieldset>
        <fieldset>
          <legend>Yhdistyksen säännöt ja ehdot</legend>
          <div>
            <Input>
              {({ inputRef }) => (
                <div className="control">
                  <label
                    css={`
                      user-select: none;
                    `}
                  >
                    <input
                      type="checkbox"
                      ref={inputRef}
                      checked={
                        typeof input["Säännöt hyväksytty"] !== "undefined"
                      }
                      onChange={e => {
                        setInput(draft => {
                          if (e.target.checked)
                            draft[
                              "Säännöt hyväksytty"
                            ] = new Date().toISOString().substr(0, 10);
                          else delete draft["Säännöt hyväksytty"];
                        });
                      }}
                    />{" "}
                    Kyllä, olen tutustunut{" "}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      onClick={e => {
                        e.preventDefault();
                        setShowSäännöt(true);
                      }}
                      href=""
                      css={`
                        color: #000;
                        text-decoration: none;
                        box-shadow: inset 0px -2px 0px #000;
                      `}
                    >
                      sääntöihin
                    </a>{" "}
                    ja haluan/haluamme liittyä Sundsbergin Kartanonrannan
                    asukasyhdistys - Sundsbergs Herrgårdstrands
                    innevånareförening -nimiseen yhdistykseen
                  </label>
                </div>
              )}
            </Input>
          </div>
        </fieldset>
        {serverData !== null && (
          <fieldset>
            <legend>Jäsentietojen poistaminen</legend>
            <div>
              <Input>
                {({ inputRef }) => (
                  <div className="control">
                    <label
                      css={`
                        user-select: none;
                        color: #e00;
                      `}
                    >
                      <input
                        type="checkbox"
                        ref={inputRef}
                        checked={
                          typeof input["Poista kaikki tiedot"] !==
                            "undefined" && input["Poista kaikki tiedot"]
                        }
                        onChange={e => {
                          setInput(draft => {
                            if (
                              e.target.checked &&
                              prompt(
                                'Oletko varma että haluat poistaa tietosi Sundsbergin Kartanonrannan asukasyhdistyksen tiedoista? Vastaa "kyllä" jos haluat poistua asukasyhdistyksestä.',
                                ""
                              ) === "kyllä"
                            )
                              draft["Poista kaikki tiedot"] = true;
                            else delete draft["Poista kaikki tiedot"];
                          });
                        }}
                      />{" "}
                      Poista kaikki yllä olevat tiedot Sundsbergin
                      Kartanonrannan asukasyhdistyksen jäsenrekisteristä.
                    </label>
                  </div>
                )}
              </Input>
            </div>
          </fieldset>
        )}
        <div
          style={{ marginTop: 20 }}
          css={`
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            > button {
              margin-right: 20px;
            }
          `}
        >
          <button
            disabled={saving}
            css={`
              transition-duration: 0.15s;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-property: background-color, border-color, color,
                box-shadow;
              background: #5850ec;
              color: #fff;
              border: 1px solid transparent;
              border-radius: 0.375rem;
              padding: 0.5rem 1rem;
              outline: none;
              font-size: 18px;
              cursor: pointer;
              &:hover {
                background: #6875f5;
              }
              &:focus {
                box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                border-color: #5145cd;
              }
              &:disabled {
                opacity: 0.25;
              }
            `}
            onClick={e => {
              e.preventDefault();
              if (
                typeof input["Jäsenet"].find(
                  v =>
                    typeof v["Sähköposti"] !== "undefined" &&
                    v["Sähköposti"].toLowerCase() ===
                      profile["Sähköposti"].toLowerCase()
                ) === "undefined" &&
                input["Jäsenet"].filter(
                  v =>
                    typeof v["Sähköposti"] !== "undefined" &&
                    v["Sähköposti"] !== ""
                ).length > 0
              ) {
                setShowNewEmailModal(true);
                return;
              }
              setSaving(true);
              (async () => {
                const response = await fetch(`/api/perhe/save`, {
                  method: "POST",
                  mode: "cors",
                  cache: "no-cache",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Tunniste ${tunniste}`,
                  },
                  body: JSON.stringify({
                    old: serverData,
                    new: input,
                  }),
                });
                setSaving(false);
                if (response.status === 403) return clearTunniste();
                const data = await response.json();
                if (response.status !== 200) return setError(data.error);
                if (input["Poista kaikki tiedot"])
                  setError("Jäsentiedot poistettu. Kirjaudutaan ulos.");
                else setError("Jäsentiedot tallennettu!");
              })();
            }}
          >
            Tallenna
          </button>
          <Link
            to="/"
            css={`
              font-family: Arial, sans-serif;
              color: #00e;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            `}
          >
            Peruuta
          </Link>
        </div>
      </Form>
      {showSäännöt && (
        <Modal
          onClose={() => {
            setShowSäännöt(false);
          }}
        >
          <Säännöt />
        </Modal>
      )}
      {typeof error !== "undefined" && (
        <Message
          onClose={() => {
            if (error === "Jäsentiedot poistettu. Kirjaudutaan ulos.") {
              (async () => {
                await fetch(`/api/logout`, {
                  method: "POST",
                  mode: "cors",
                  cache: "no-cache",
                  headers: {
                    Authorization: `Tunniste ${tunniste}`,
                  },
                });
                clearTunniste();
                history.push("/");
              })();
              return;
            }
            if (
              error === "Jäsentiedot tallennettu! Kirjaudu uudelleen sisään."
            ) {
              (async () => {
                await fetch(`/api/logout`, {
                  method: "POST",
                  mode: "cors",
                  cache: "no-cache",
                  headers: {
                    Authorization: `Tunniste ${tunniste}`,
                  },
                });
                clearTunniste();
                history.push("/");
              })();
            }
            if (error === "Jäsentiedot tallennettu!") {
              history.push("/");
            }
            setError(undefined);
          }}
        >
          <p
            css={`
              margin: 0;
              margin-bottom: 10px;
            `}
          >
            {error}
          </p>
        </Message>
      )}
      {showNewEmailModal && (
        <>
          <Modal
            onClose={() => {
              setShowNewEmailModal(false);
            }}
          >
            <div
              style={{
                maxWidth: 480,
                margin: "0 auto 0",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <p>
                Sähköpostiosoite jolla kirjauduit alunperin sisään ei löydy enää
                jäsentiedoista. Vahvista jokin uusista sähköpostiosoitteista
                kirjautumalla sillä sisään.
              </p>
              <ul>
                {input.Jäsenet.filter(
                  v =>
                    typeof v["Sähköposti"] !== "undefined" &&
                    v["Sähköposti"] !== ""
                ).map(v => (
                  <li>{v["Sähköposti"]}</li>
                ))}
              </ul>
            </div>
            <Sisäänkirjautuminen
              plain
              onTunniste={tunniste => {
                /* use new ephemeral tunniste for saving */
                setShowNewEmailModal(false);
                (async () => {
                  const response = await fetch(`/api/perhe/save`, {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Tunniste ${tunniste}`,
                    },
                    body: JSON.stringify({
                      old: serverData,
                      new: input,
                    }),
                  });
                  if (response.status === 403) return clearTunniste();
                  const data = await response.json();
                  if (response.status !== 200) {
                    (async () => {
                      await fetch(`/api/logout`, {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        headers: {
                          Authorization: `Tunniste ${tunniste}`,
                        },
                      });
                    })();
                    return setError(data.error);
                  }
                  if (input["Poista kaikki tiedot"])
                    setError("Jäsentiedot poistettu. Kirjaudutaan ulos.");
                  else {
                    setError(
                      "Jäsentiedot tallennettu! Kirjaudu uudelleen sisään."
                    );
                    /* logout from ephemeral tunniste */
                    (async () => {
                      await fetch(`/api/logout`, {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        headers: {
                          Authorization: `Tunniste ${tunniste}`,
                        },
                      });
                    })();
                  }
                })();
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default App;
