import React from "react";
import "styled-components/macro";
import { SessionContext } from "./index";
import { Form, Input } from "./Jäsenlomake";
import produce from "immer";
import ReactDOM from "react-dom";

interface Maksutiedot {
  summa: string;
  aihe: "Sauna" | "Tilavuokra" | "Ilotulitus" | "Muu";
  viesti: string;
}

export const SendMoney: React.FC = () => {
  const { tunniste } = React.useContext(SessionContext);
  const [input, _setInput] = React.useState<Maksutiedot>({
    summa: "",
    aihe: "Sauna",
    viesti: "",
  });
  const setInput = React.useCallback(
    (cb: (arg0: Maksutiedot) => void) => {
      _setInput(produce(input, cb));
    },
    [input]
  );
  const summa = (() => {
    const parsed = parseFloat(input["summa"].replace(",", "."));
    if (parseFloat(parsed.toFixed(2)) !== parsed) return undefined;
    return parsed.toFixed(2);
  })();
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <Form
        width={480}
        onSubmit={e => {
          e.preventDefault();
          (async () => {
            setLoading(true);
            const res = await fetch(`/api/pay`, {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Tunniste ${tunniste}`,
              },
              body: JSON.stringify({
                summa,
                aihe: input.aihe,
                viesti: input.viesti,
              }),
            });
            setLoading(false);
            const { hosted_invoice_url } = await res.json();
            if (typeof hosted_invoice_url !== "undefined") {
              setLoading(true);
              window.location.href = hosted_invoice_url;
            }
          })();
        }}
      >
        <Avoimet />
        <fieldset>
          <legend>Tee maksu yhdistykselle</legend>
          <div>
            <Input label="Summa" required>
              {({ inputRef }) => (
                <input
                  ref={inputRef}
                  value={input["summa"]}
                  onChange={e => {
                    setInput(draft => {
                      draft["summa"] = e.target.value;
                    });
                  }}
                  disabled={loading}
                />
              )}
            </Input>
            <Input label="Aihe" required>
              {({ inputRef }) => (
                <select
                  ref={inputRef}
                  value={input["aihe"]}
                  onChange={e => {
                    setInput(draft => {
                      draft["aihe"] = e.target.value as Maksutiedot["aihe"];
                    });
                  }}
                  disabled={loading}
                >
                  <option value="Sauna">Sauna</option>
                  <option value="Tilavuokra">Tilavuokra</option>
                  <option value="Ilotulitus">Ilotulitus</option>
                  <option value="Muu">Muu</option>
                </select>
              )}
            </Input>
          </div>
          <div>
            <Input label="Viesti">
              {({ inputRef }) => (
                <textarea
                  ref={inputRef}
                  rows={4}
                  value={input["viesti"]}
                  onChange={e => {
                    setInput(draft => {
                      draft["viesti"] = e.target.value;
                    });
                  }}
                  disabled={loading}
                />
              )}
            </Input>
          </div>
        </fieldset>
        <div style={{ marginTop: 20 }}>
          <button
            css={`
              margin-bottom: 20px;
              transition-duration: 0.15s;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-property: background-color, border-color, color,
                box-shadow;
              background: #5850ec;
              color: #fff;
              border: 1px solid transparent;
              border-radius: 0.375rem;
              padding: 0.5rem 1rem;
              outline: none;
              font-size: 18px;
              cursor: pointer;
              &:hover {
                background: #6875f5;
              }
              &:focus {
                box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                border-color: #5145cd;
              }
              &:disabled {
                background: #e4e4e4;
              }
            `}
            disabled={typeof summa === "undefined" || loading}
          >
            Luo lasku ja siirry maksamaan
            {typeof summa !== "undefined" && (
              <> ({summa.replace(".", ",")} €)</>
            )}
          </button>
        </div>
      </Form>
      {loading && <Loading />}
    </>
  );
};

const Avoimet: React.FC = () => {
  const [data, setData] = React.useState<
    | {
        invoices: {
          id: string;
          description: string;
          amount: string;
          hosted_invoice_url: string;
        }[];
      }
    | undefined
  >(undefined);
  const { tunniste } = React.useContext(SessionContext);
  React.useEffect(() => {
    let cancel = false;
    (async () => {
      const response = await fetch(`/api/pay-list-open-payments`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Tunniste ${tunniste}`,
        },
      });
      if (cancel) return;
      const data = await response.json();
      if (cancel) return;
      setData(data);
    })();
    return () => {
      cancel = true;
    };
  }, [tunniste]);
  if (typeof data === "undefined") return <></>;
  return (
    <>
      {data.invoices.map(v => (
        <>
          <fieldset>
            <legend>Avoin maksu</legend>
            <div>
              <Input label="Viesti">{() => <>{v.description}</>}</Input>
            </div>
            <div>
              <Input label="Summa">{() => <>{v.amount}</>}</Input>
              <Input label="Poista avoin maksu">
                {() => (
                  <>
                    <PoistaNappi id={v.id} />
                  </>
                )}
              </Input>
            </div>
            <br />
            <button
              css={`
                transition-duration: 0.15s;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-property: background-color, border-color, color,
                  box-shadow;
                background: #5850ec;
                color: #fff;
                border: 1px solid transparent;
                border-radius: 0.375rem;
                padding: 0.5rem 1rem;
                outline: none;
                font-size: 18px;
                cursor: pointer;
                &:hover {
                  background: #6875f5;
                }
                &:focus {
                  box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
                  border-color: #5145cd;
                }
                &:disabled {
                  background: #e4e4e4;
                }
              `}
              onClick={e => {
                e.preventDefault();
                window.location.href = v.hosted_invoice_url;
              }}
            >
              Siirry maksamaan
            </button>
          </fieldset>
        </>
      ))}
    </>
  );
};

const PoistaNappi: React.FC<{ id: string }> = ({ id }) => {
  const { tunniste } = React.useContext(SessionContext);
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <button
        css={`
          transition-duration: 0.15s;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-property: background-color, border-color, color, box-shadow;
          background: #5850ec;
          color: #fff;
          border: 1px solid transparent;
          border-radius: 0.375rem;
          padding: 0.5rem 1rem;
          outline: none;
          font-size: 18px;
          cursor: pointer;
          &:hover {
            background: #6875f5;
          }
          &:focus {
            box-shadow: 0 0 0 3px rgba(180, 198, 252, 0.45);
            border-color: #5145cd;
          }
          &:disabled {
            background: #e4e4e4;
          }
        `}
        disabled={loading}
        onClick={e => {
          e.preventDefault();
          setLoading(true);
          (async () => {
            (async () => {
              const response = await fetch(
                `/api/pay-delete-open-payment/${encodeURIComponent(id)}`,
                {
                  method: "DELETE",
                  cache: "no-cache",
                  headers: {
                    Authorization: `Tunniste ${tunniste}`,
                  },
                }
              );
              await response.json();
              window.location.reload();
            })();
          })();
        }}
      >
        Poista
      </button>
    </>
  );
};

const Loading: React.FC = () => {
  const el = React.useMemo(() => document.createElement("div"), []);
  React.useLayoutEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  }, [el]);
  return (
    <>
      {ReactDOM.createPortal(
        <div
          css={`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255, 255, 255, 0.5);
            user-select: none;
          `}
        ></div>,
        el
      )}
    </>
  );
};
